import React, { FC } from 'react';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';

import { LegalProps } from './models';

import './Legal.scss';

const Legal: FC<{ data: LegalProps }> = ({
  data: {
    legal: { pageLogo, title, body, urls, seo },
    footer,
    header,
    siteSettings,
  },
}) => {
  if (!body) return null;
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  return (
    <Layout headerTransparent alterLogo={pageLogo || null}>
      {header && <Header data={header} settings={siteSettings} />}

      <Seo {...{ urls, seo }} />

      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />

      <div className="mx-auto section-container legal-page">
        {title ? <h1 className="legal-page__title text-center mt-5">{title}</h1> : <></>}
        {body ? (
          <DangerouslySetInnerHtml className="legal-page__body" html={body as string} />
        ) : (
          <></>
        )}
      </div>

      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $lang: String) {
    legal(url: { eq: $url }) {
      pageLogo {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      title
      body
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Legal;
